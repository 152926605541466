import { render, staticRenderFns } from "./AppSetting.vue?vue&type=template&id=1f2932cc&"
import script from "./AppSetting.vue?vue&type=script&lang=js&"
export * from "./AppSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\wamp\\www\\laravel\\rawal\\rawal-laravel\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f2932cc')) {
      api.createRecord('1f2932cc', component.options)
    } else {
      api.reload('1f2932cc', component.options)
    }
    module.hot.accept("./AppSetting.vue?vue&type=template&id=1f2932cc&", function () {
      api.rerender('1f2932cc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/AppSetting.vue"
export default component.exports