import { render, staticRenderFns } from "./LargeMediaSetting.vue?vue&type=template&id=4c20b9b4&"
import script from "./LargeMediaSetting.vue?vue&type=script&lang=js&"
export * from "./LargeMediaSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\wamp\\www\\laravel\\rawal\\rawal-laravel\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4c20b9b4')) {
      api.createRecord('4c20b9b4', component.options)
    } else {
      api.reload('4c20b9b4', component.options)
    }
    module.hot.accept("./LargeMediaSetting.vue?vue&type=template&id=4c20b9b4&", function () {
      api.rerender('4c20b9b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/admin/settings/LargeMediaSetting.vue"
export default component.exports